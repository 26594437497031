import React from "react";

import Page from "../../components/Page";
import BannerParallax from "../../components/BannerParallax";

import imgBackground from "../../images/banner/banner-2-music-teacher.jpg";
import OurTeachers from "./MusicTeachers";

export default () => {
  const pageTitle = "Music Teachers";
  return (
    <Page title={pageTitle}>
      <BannerParallax title={pageTitle} background={imgBackground} />
      <OurTeachers />
    </Page>
  );
};
