import React from "react";
import { Card } from "react-bootstrap";
import IconSocmed from "../../components/IconSocmed";

import imgAbel from "../../images/our-teachers/Abel.jpg";
import imgAchi from "../../images/our-teachers/Achi.jpg";
import imgAnas from "../../images/our-teachers/Anas.jpg";
import imgAnggita from "../../images/our-teachers/Anggita.jpg";
import imgAsti from "../../images/our-teachers/Asti.jpg";
import imgAstrid from "../../images/our-teachers/Astrid.jpg";
import imgAttar from "../../images/our-teachers/Attar.jpg";
import imgBrigitta from "../../images/our-teachers/Brigitta.jpg";
import imgCati from "../../images/our-teachers/Cati.jpg";
import imgEcha from "../../images/our-teachers/Echa.jpg";
import imgGanjar from "../../images/our-teachers/Ganjar.jpg";
import imgIndri from "../../images/our-teachers/Indri.jpg";
import imgIwan from "../../images/our-teachers/Iwan-edit.png";
import imgLeo from "../../images/our-teachers/Leo.jpg";
import imgNana from "../../images/our-teachers/Nana.jpg";
import imgNanin from "../../images/our-teachers/Nanin.jpg";
import imgNico from "../../images/our-teachers/Nico.jpg";
import imgRahel from "../../images/our-teachers/Rahel.jpg";
import imgRani from "../../images/our-teachers/Rani.jpg";
import imgReza from "../../images/our-teachers/Reza.jpg";
import imgTanti from "../../images/our-teachers/Tanti.jpg";
import imgTarra from "../../images/our-teachers/Tarra.jpg";
import imgVictor from "../../images/our-teachers/Victor.jpg";
import imgYasintha from "../../images/our-teachers/Yasintha.jpg";

export default () => {
  let teachers = [
    {
      img: imgAbel,
      name: "Abel Prasetyo",
      ig: "abelprasetyo",
      instrument: " Piano"
    },
    {
      img: imgAchi,
      name: "Achi Hardjakusumah",
      ig: "archihardjakusumah",
      instrument: "Violin, Piano"
    },
    {
      img: imgAnas,
      name: "Anastasia P. Brannan",
      ig: "anastasia.brannan",
      instrument: "Vocal"
    },
    {
      img: imgAnggita,
      name: "Anggita Madeira",
      ig: "anggitamadeira",
      instrument: "Piano"
    },
    {
      img: imgAsti,
      name: "Asti Fajriani",
      ig: "astifajriani",
      instrument: "Piano, Vocal"
    },
    {
      img: imgAstrid,
      name: "Astrid Anggraeni",
      ig: "astrid.anggraeni",
      instrument: "Piano, Music Theory"
    },
    {
      img: imgAttar,
      name: "Attar Nasution",
      ig: "attarnasution",
      instrument: "Guitar, Ukulele, Digital Music"
    },
    {
      img: imgBrigitta,
      name: "Brigitta Stephany",
      ig: "oubridget",
      instrument: "Piano, Vocal"
    },
    {
      img: imgCati,
      name: "Cecillia Cati",
      ig: "cecillia_cati",
      instrument: "Vocal, Choir, Music For Little Mozarts"
    },
    {
      img: imgEcha,
      name: "Echa (Desra Sadia)",
      ig: "echa.desra",
      instrument: "Violin, Cello, String Chamber"
    },
    {
      img: imgGanjar,
      name: "Ganjar Aruji",
      ig: "ganjararuji",
      instrument: "Guitar"
    },
    {
      img: imgIndri,
      name: "Dwiani Indraningsri",
      ig: "indridwiani",
      instrument: "Flute, Piano, Music Theory & Composition"
    },
    {
      img: imgIwan,
      name: "Iwan Paul",
      ig: "iwan_paul",
      instrument: "Drum, Band"
    },
    {
      img: imgLeo,
      name: "Leonardus Mulyanto",
      ig: "leonardusjm",
      instrument: "Saxophone, Trumpet, Clarinet"
    },
    {
      img: imgNana,
      name: "Chrisanti Andanawari",
      ig: "nana.chrisanti",
      instrument: "Piano, Tchaikovsky Toddlers"
    },
    {
      img: imgNanin,
      name: "Nanin Wardhani",
      ig: "naninwardhani",
      instrument: "Piano, Digital Music"
    },
    {
      img: imgNico,
      name: "Nicolaus Edwin",
      ig: "nicolausedwin",
      instrument: "Saxophone, Flute, Clarinet, Digital Music"
    },
    {
      img: imgRahel,
      name: "Rahel Pradika",
      ig: "rahelpradika",
      instrument: "Vocal, Digital Music"
    },
    {
      img: imgRani,
      name: "Maharani Seba",
      ig: "",
      instrument: "Tchaikovsky Toddlers, Music For Little Mozarts"
    },
    {
      img: imgReza,
      name: "Areza Riandra",
      ig: "arezariandra",
      instrument: "Drums, Piano, Band, Digital Music"
    },
    {
      img: imgTanti,
      name: "Tanti Nur Fajri",
      ig: "tantinurfajri",
      instrument: "Vocal, Piano"
    },
    {
      img: imgTarra,
      name: "Tarra Sitorus",
      ig: "tarrasitorus",
      instrument: "Piano, Vocal"
    },
    {
      img: imgVictor,
      name: "Victor Alam",
      ig: "victoralam",
      instrument: "Bass, Guitar"
    },
    {
      img: imgYasintha,
      name: "Yasintha Pattiasina",
      ig: "yaspatt",
      instrument: "Violin"
    }
  ];

  return (
    <section className="section_padding">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-12">
            <div className="section_title text-center">
              <h2>Music Teachers</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {teachers.map((teacher, i) => (
            <div key={i} className="col-sm-12 col-md-3 mb-md-4 mb-2">
              <Card>
                <Card.Img variant="top" src={teacher.img} />
                <Card.Body>
                  <Card.Title>{teacher.name}</Card.Title>
                  <Card.Subtitle style={{ minHeight: "38px" }}>
                    {teacher.instrument}
                  </Card.Subtitle>
                </Card.Body>
                {teacher.ig && (
                  <Card.Footer>
                    <Card.Link
                      href={"https://instagram.com/" + teacher.ig}
                      className="align-items-center d-flex"
                    >
                      <IconSocmed instagram />
                      <span className="ml-2">@{teacher.ig}</span>
                    </Card.Link>
                  </Card.Footer>
                )}
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
